<template>
    <div :style="`min-width: 150px; width: ${onlyCard ? '175px' : ''}`">
        <q-card flat bordered :class="`${! onlyCard ? 'readonly-' : ''}data-card bg-lightPrimary self-center`" style="border-color: #111715; border-radius: 12px; border-width: 2px;">
            <q-card-section key="description" :class="`q-pa-none ${onlyCard ? 'cursor-pointer' : ''}`">
                <q-list class="q-pa-none">
                    <q-card-section class="col-12 q-pa-none">
                        <q-skeleton height="171px" width="171px" square animation="fade" style="border-top-left-radius: 8px; border-top-right-radius: 8px;"/>
                    </q-card-section>
                    <div class="row q-px-none q-pt-none q-pb-none justify-between" style="min-height: 20px; max-height: 45px;">
                        <div key="name" class="q-px-sm q-pb-none q-pt-none row justify-between" style="width: 100%;">
                            <q-skeleton height="30px" width="100px" type="text" animation="fade" />
                            <q-skeleton height="30px" width="25px" type="text" animation="fade" />
                        </div>
                    </div>
                    <div class="row q-px-none q-pt-none q-pb-none justify-between" style="min-height: 20px; max-height: 45px;">
                        <div key="name" class="q-px-sm q-pb-none q-pt-none row justify-between" style="width: 100%;">
                            <q-skeleton height="30px" width="50px" type="text" animation="fade" />
                            <q-skeleton height="30px" width="50px" type="text" animation="fade" />
                        </div>
                    </div>
                </q-list>
            </q-card-section>
        </q-card>
    </div>
</template>

<script>

export default {
  name: 'SkeletonCard',
  props: {
    onlyCard: Boolean,
  },
  setup () {

    return {
    }
  },
  components: {
  },
}
</script>