const projectStore = {
    namespaced: true,
    state: () => ({
        projects: [],
        activeProject : {},
        projectLoading: false,
    }),
    mutations: {
        setProjects(state, projects) {
          state.projects = projects;
        },
        setActiveProject(state, project) {
          state.activeProject = project;
        },
        setProjectLoading(state, loading) {
          state.projectLoading = loading;
        },
    },
    actions: {
        async getProjects(context) {
          console.log(`${! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/project`)
            await fetch(`${! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/project`, {
              method: 'get',
              headers: { 'Content-type': 'application/json' },
            }).then( async (response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }

              let data = await response.json()
              
              context.commit('setProjects', data.projects.sort((a, b) => a.name.localeCompare(b.name)) || []);
            }).catch((error) => {
              console.log('Looks like there was a problem: \n', error);
            });
        },
        async getProjectById(context, id) {
            await fetch(`${! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/project/${id}`, {
              method: 'get',
              headers: { 'Content-type': 'application/json' },
            }).then( async (response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }

              let data = await response.json()
              
              context.commit('setActiveProject', data.project || []);
              await context.dispatch('collectionStore/getCollection', {uuid: data.project.uuid, page: 1, query: ''}, {root:true});
              context.commit('setProjectLoading', false);
            }).catch((error) => {
              console.log('Looks like there was a problem: \n', error);
            });
        },
        async getProjectByShortName(context, data) {
            await fetch(`${! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/project?shortname=${data.shortName}`, {
              method: 'get',
              headers: { 'Content-type': 'application/json' },
            }).then( async (response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }

              let data = await response.json()
              
              context.commit('setActiveProject', data.projects || {});

              context.dispatch('traitStore/getTraitsByProject', data.projects.uuid, {root: true} );
            }).catch((error) => {
              console.log('Looks like there was a problem: \n', error);
            });
        }
    },
    getters: {
       getProjects(state) {
        return state.projects;
       },
       getActiveProject(state) {
        return state.activeProject;
       },
       getProjectLoading(state) {
         return state.projectLoading;
       }
    }  
}

export default projectStore;