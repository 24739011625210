<template>
    <q-page padding class="q-pa-none bg-secondary text-accent" style="height: calc( 100vh - 30px ); padding-top: 10px;">
        <div class="q-px-md justify-start">
            <div class="text-h6">Available Projects:</div>
        </div>
        <div class="q-pa-md row q-gutter-sm justify-start">
            <DataCard 
                v-for="(project, index) in projects"
                :key="index"
                :data="project"
                :onlyCard="true"
                dataType="project"
            ></DataCard>
            <q-card v-if="false" flat bordered :class="`bg-lightPrimary self-center`" style="border-color: #111715; border-radius: 12px; border-width: 2px;">
                <q-card-section key="description" class="q-pa-none">
                    <q-card-section class="col-12 q-pa-sm">
                        <q-btn
                        padding="65.5px 65.5px"
                        color="primary"
                        icon="fas fa-plus"
                        flat
                        />
                    </q-card-section>
                    <div class="row q-px-none q-pt-none q-pb-xs justify-between" style="min-height: 20px; max-height: 45px;">
                        <div key="name" class="q-px-sm q-pb-sm q-pt-none row justify-between" style="width: 100%;">
                            <div :class="`col-12 text-caption text-accent`">Add Your Project</div>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </div>
    </q-page>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import DataCard from '../components/DataCard.vue';

export default {
  name: 'HomeView',
  props: {
  },
  setup () {
    const store = useStore(),
        projects = computed(() => store.getters["projectStore/getProjects"]);

    onMounted( async () => {
        await store.dispatch('projectStore/getProjects');
    });

    return {
        projects,
    }
  },
  components: {
    DataCard,
  },
}
</script>
