import { render } from "./SkeletonCard.vue?vue&type=template&id=4b005e7a"
import script from "./SkeletonCard.vue?vue&type=script&lang=js"
export * from "./SkeletonCard.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QList,QSkeleton});
