<template>
    <q-page padding class="q-pa-none bg-secondary text-accent" style="height: calc( 100vh - 30px );">
        <div class="q-pa-none flex bg-secondary">
            <q-img img-class="bg-black" :src="activeProject.banner_image_url" style="height: 150px;"/>
            <q-img ratio="1" style="max-height: 100px; max-width: 100px; border-radius: 50%; margin-top: -50px; margin-left: 30px; border: 2px solid #F6E9A2;" round img-class="bg-black" :src="activeProject.profile_image_url"/>
            <div class="q-px-md q-py-sm flex bg-secondary" style="width: calc( 100% - 130px );">
                <div class="flex row justify-between" style="width: 100%;">
                    <div class="text-h5 text-accent text-weight-bold" v-if="activeProject">{{activeProject.name}}</div>
                    <div class="q-pr-md q-pa-none">
                        <q-btn-group outline>
                            <q-btn v-if="activeProject.website_url" color="accent" outline @click="openLink(activeProject.website_url)">
                                <q-icon name="web" color="accent"/>
                            </q-btn>
                            <q-btn v-if="activeProject.discord_url" color="accent" outline @click="openLink(activeProject.discord_url)">
                                <q-icon name="fab fa-discord" color="accent"/>
                            </q-btn>
                            <q-btn v-if="activeProject.twitter_url" color="accent" outline @click="openLink(activeProject.twitter_url)">
                                <q-icon name="fab fa-twitter" color="accent"/>
                            </q-btn>
                        </q-btn-group>
                    </div>
                </div>
            </div>
            <div class="q-pa-md flex bg-secondary justify-center flex-center" style="height: calc( 100vh - 233px ); width: 100%;">
                <q-card class="bg-lightPrimary" style="max-height: 300px; max-width: 300px;">
                    <q-card-section> 
                        <div class="q-pa-md text-h5">{{activeProject.name}} Rarity Challenge</div>
                    </q-card-section>
                    <q-card-section>
                        <div class="q-pa-md text-body1">Do you know your {{activeProject.short_name}}? Test your knowledge here!</div>
                    </q-card-section> 
                </q-card>
            </div>
        </div>
    </q-page>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'ChallengeView',
  props: {
  },
  setup () {
    const store = useStore(),
        router = useRouter(),
        activeProject = computed(() => store.getters["projectStore/getActiveProject"]);

    onMounted( async () => {
        await store.dispatch('collectionStore/getCollection', {uuid: store.getters["projectStore/getActiveProject"].uuid , page: 1, query: '' })

        if (router.currentRoute.value.params.shortName) {
            await store.dispatch('projectStore/getProjectByShortName', {shortName: router.currentRoute.value.params.shortName, page: 1, query: '' })
        }
    });

    return {
        activeProject,
    }
  },
  components: {
  },
}
</script>
