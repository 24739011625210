import { render } from "./DataCard.vue?vue&type=template&id=ec1eb530&scoped=true"
import script from "./DataCard.vue?vue&type=script&lang=js"
export * from "./DataCard.vue?vue&type=script&lang=js"

import "./DataCard.vue?vue&type=style&index=0&id=ec1eb530&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-ec1eb530"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QList,QImg,QItemLabel,QTooltip,QItem,QBtnToggle});
