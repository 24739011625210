import { render } from "./ChallengeView.vue?vue&type=template&id=3a180b58"
import script from "./ChallengeView.vue?vue&type=script&lang=js"
export * from "./ChallengeView.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QImg,QBtnGroup,QBtn,QIcon,QCard,QCardSection});
