import { render } from "./RarityView.vue?vue&type=template&id=06dc01eb"
import script from "./RarityView.vue?vue&type=script&lang=js"
export * from "./RarityView.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QScrollArea,QLinearProgress,QImg,QBtnGroup,QBtn,QIcon,QSkeleton,QBtnToggle,QChip,QPagination});
