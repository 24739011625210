<template>
    <div :style="`min-width: 150px; width: ${onlyCard ? '175px' : ''}`">
        <q-card flat bordered :class="`${! onlyCard ? 'readonly-' : ''}data-card bg-lightPrimary self-center`" style="border-color: #111715; border-radius: 12px; border-width: 2px;" @click="dataCardClicked">
            <q-card-section key="description" :class="`q-pa-none ${onlyCard ? 'cursor-pointer' : ''}`">
                <q-list class="q-pa-none">
                    <q-card-section class="col-12 q-pa-none" style="overflow: hidden; border-top-left-radius: 8px; border-top-right-radius: 8px; border-bottom: 2px solid #111715;">
                        <q-img :ratio="1" img-class="data-card__image bg-black" :src="dataImage"/>
                    </q-card-section>
                    <div class="row q-px-none q-pt-none q-pb-none justify-between" style="line-height: 20px; min-height: 20px; max-height: 45px; overflow: hidden;">
                        <div key="name" class="q-px-sm q-pb-none q-pt-xs row justify-between text-subtitle2 text-weight-bold text-no-wrap" style="width: 100%; line-height: 20px;">
                            <div :class="`${dataType === 'project' ? 'col-12' : 'col-8'} text-accent ellipsis`" v-if="onlyCard && data.name">{{data.name}}</div>
                            <div :class="`${dataType === 'project' ? 'col-12' : 'col-8'} text-accent ellipsis`" v-else-if="data.name">{{data.name}}</div>
                            <div class="q-pa-none col-4 row justify-end" v-if="data.rank">
                                <q-item-label class="q-px-xs q-pb-none q-pt-xs text-subtitle2 text-text text-weight-bold" style="margin-top: -2px;">#{{dataRank}}</q-item-label>
                            </div>
                        </div>
                    </div>
                    <div v-if="dataType !== 'project'" class="row q-px-none q-pt-none q-pb-sm justify-between text-weight-bold" style="line-height: 20px; min-height: 20px; max-height: 45px; overflow: hidden;">
                        <div key="name" class="q-px-sm q-py-none row justify-between text-caption text-no-wrap" style="width: 100%; min-height: 20px;">
                            <div :class="`col-5 text-accent`">
                                <div v-if="openSeaPrice && openSeaPrice.basePrice" class="flex row" @click="openMarketplace">
                                    <q-img :src="openSeaPrice.symbolURL" fit="contain" style="max-height: 20px; max-width: 10px; background-">
                                        <q-tooltip :class="`bg-primary`">{{openSeaPrice.symbol}}</q-tooltip>
                                    </q-img>
                                    <div class="q-pl-sm" style="max-width: calc( 100% - 15px )">{{roundPrice(openSeaPrice.basePrice, 3)}}</div>
                                </div>
                            </div>
                            <div v-if="openSeaInfo && openSeaInfo.address" :class="`col-6 text-accent ellipsis row justify-end`">
                                <a target="_blank" rel="noopener noreferrer" :href="`https://bozp.dev/?wallet=${openSeaInfo.address}`" class="q-pl-sm text-primary">
                                    <q-tooltip :class="`bg-primary`">{{openSeaInfo.address}}</q-tooltip>
                                    {{openSeaInfo.address.substring(0,8)}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <q-item v-if="!onlyCard && data.description" class="row justify-center">
                        <div class="q-pa-sm text-text row">{{data.description}}</div>
                    </q-item>
                    <q-item v-if="!onlyCard" class="q-pt-none row justify-center">
                        <div class="q-py-xs q-px-sm text-h6 bg-secondary text-text row" style="border-radius: 12px;"><div class="text-accent text-h6">Rarity Score:</div>&nbsp;{{dataRarityScore}}</div>
                    </q-item>
                </q-list>
            </q-card-section>
        </q-card>
        <div v-if="!onlyCard && computedTraits.length > 0">
            <div class="q-pa-sm" v-if="false">
                <q-btn-toggle
                    v-model="raritySorting"
                    spread
                    class="rarity-toggle"
                    no-caps
                    rounded
                    unelevated
                    toggle-color="primary"
                    color="secondary"
                    text-color="accent"
                    :options="rarityOptions"
                />
            </div>
            <q-item class="q-px-xs q-py-none column" v-for="(attribute, index) in computedTraits" :key="index">
                <div v-if="attribute.trait_type" :class="`q-px-xs q-py-none row justify-between align-center`"> 
                    <div class="q-py-xs text-accent text-subtitle2" style="text-transform:capitalize;">{{attribute.trait_type}}</div>
                    <div class="q-py-xs text-text text-subtitle2">+&nbsp;{{attribute.rarity_score.toFixed(2)}}</div>
                </div>
                <div v-else :class="`q-px-xs q-py-none row justify-between align-center`"> 
                    <div class="q-py-xs text-accent text-subtitle2" style="text-transform:capitalize;">Attribute Count</div>
                    <div class="q-py-xs text-text text-subtitle2">+&nbsp;{{attribute.rarity_score.toFixed(2)}}</div>
                </div>
                <div v-if="attribute" :class="`q-pa-xs row justify-between align-center`" style="width: 100%;"> 
                    <div v-if="attribute.value" :class="`flex text-body2 text-accent cursor-pointer row ${activeFilterStyle(attribute.trait_type || 'attributeCount' ,attribute.value || attribute.count)}`" style="border-radius: 12px; text-transform:capitalize; width: 100%; padding: 2px; padding-top: 3px;" @click="filterAttribute(attribute.trait_type, attribute.value)">
                        <div class="col-8 q-px-xs">{{attribute.value}}</div>
                        <div class="col-4 q-px-xs bg-primary text-text" style="border-radius: 12px; padding-left: 8px; padding-top: 1px;">{{traits[attribute.trait_type][attribute.value]}}</div>
                    </div>
                    <div v-else-if="attribute.count" :class="`flex text-body2 text-accent cursor-pointer row ${activeFilterStyle(attribute.trait_type || 'attributeCount' ,attribute.value || attribute.count)}`" style="border-radius: 12px; text-transform:capitalize; width: 100%; padding: 2px; padding-top: 3px;" @click="filterAttribute('attributeCount', attribute.count)">
                        <div class="col-8 q-px-xs">{{attribute.count}}</div>
                        <div class="col-4 q-px-xs bg-primary text-text" style="border-radius: 12px; padding-left: 8px; padding-top: 1px;">{{traits["attributeCount"][attribute.count]}}</div>
                    </div>
                    <div v-else :class="`flex text-body2 text-accent cursor-pointer row ${activeFilterStyle(attribute.trait_type ,'total_count')}`" style="border-radius: 12px; width: 100%; padding: 2px; padding-top: 3px;" @click="filterAttribute(attribute.trait_type, 'total_count')">
                        <div class="col-8 q-px-xs">{{`None`}}</div>
                        <div class="col-4 q-px-xs bg-primary text-text" style="border-radius: 12px; padding-left: 8px; padding-top: 1px;">{{totalCount - traits[attribute.trait_type]["total_count"]}}</div>
                    </div>
                </div>
            </q-item>
        </div>
    </div>
</template>

<style scoped>
.data-card:hover {
    border-color: #F6E9A2 !important;
    border-width: 2px !important;
}
.rarity-toggle {
  border: 2px solid #F6E9A2
}
</style>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'DataCard',
  props: {
    data: Object,
    dataType: String,
    onlyCard: Boolean,
  },
  setup ( props) {
    const store = useStore(),
        dataImage = ref( props.data.image || props.data.profile_image_url),
        dataId = ref(props.data.id || props.data.uuid),
        dataRank = ref(props.data.rank),
        openSeaPrice = ref({}),
        openSeaInfo = ref({}),
        raritySorting = ref("rarity"),
        rarityOptions = ref([
            {label: 'Rarity Score', value: 'rarity'},
            {label: 'Option 2', value: 'two'}
        ]),
        getNFTOpenSeaInfo = ref(store.getters["collectionStore/getNFTOpenSeaInfo"]),
        getNFTOpenSeaPrice = ref(store.getters["collectionStore/getNFTOpenSeaPrice"]),
        dataRarityScore = computed(() => {
            return props.data?.rarity_score?.toFixed(2) || 0
        }),
        activeProject = computed(() => store.getters["projectStore/getActiveProject"]),
        traits = computed(() => store.getters["traitStore/getTraits"][activeProject.value.uuid] || []),
        totalCount = computed(() => store.getters["collectionStore/getTotalCount"]),
        computedTraits = computed(() => {
            const traits = props.data.attributes.concat(props.data.missing_traits);
            traits.push(props.data.trait_count)

            if ( raritySorting.value === "rarity" ) {
                return traits.sort((trait1, trait2) => {
                    if (trait1.rarity_score < trait2.rarity_score) {
                        return 1
                    }
                    if (trait1.rarity_score > trait2.rarity_score) {
                        return -1
                    }
                    return 0
                })
            }
            return traits
        }),
        activeAttributeFilter = computed(() => {
            const attributeFilter = store.getters["collectionStore/getAttributeFilter"],
            active = {};

            attributeFilter.forEach(attribute => {
            if ( active[attribute.name] ) {
                active[attribute.name].push(attribute.value)
            }
            else {
                active[attribute.name] = [attribute.value]
            }
            })

            return active;
        });
    
    const projectUpdate = async (project) => {
        store.commit('projectStore/setProjectLoading', true)

        await store.dispatch('projectStore/getProjectById', project.uuid);
    };

    const dataCardClicked = async () => {
        if (props.dataType === 'project') {
          await store.dispatch('routerStore/routerPush', {name: 'rarityView', params:{ shortName:`${props.data.short_name}`}});
          await store.dispatch('traitStore/getTraitsByProject', props.data.uuid );
          await projectUpdate( props.data )
        }
        else if (props.onlyCard && props.data.id !== store.getters["collectionStore/getFocusedNFT"].id) {
          const uniqueId = `${props.data.id}-${activeProject.value.uuid}`;
          await store.commit('collectionStore/setLoadingRightSideBar', true)
            
          await store.dispatch('collectionStore/getNFT', uniqueId);
          await store.dispatch('routerStore/routerPush', {name: 'focusedRarity', params:{ focusedNFT: uniqueId}});
        }
    };

    const activeFilterStyle = (name, value) => {
      const attributeList = activeAttributeFilter.value[name] || [];
      
      return attributeList.indexOf(String(value)) > -1 ? 'active-attribute cursor-pointer' : 'bg-secondary cursor-pointer';
    };

    const filterAttribute = async (name, value) => {
        await store.dispatch('collectionStore/filterAttribute', {name,value})
    };

    const roundPrice = (int, decimals) => {
        return parseFloat(int) % 1 != 0 ? parseFloat(int).toFixed(decimals) : parseFloat(int)
    };

    const openMarketplace = () => {
      let url = `https://opensea.io/assets/${activeProject.value.contract_address}/${props.data.id}`;

      window.open(url, '_blank').focus();
    };

    onMounted(() => {
        if (props.dataType !== 'project') {
            openSeaPrice.value = getNFTOpenSeaPrice.value(activeProject.value.short_name, dataId.value)
            openSeaInfo.value = getNFTOpenSeaInfo.value(activeProject.value.short_name, dataId.value)
        }
    })

    return {
        dataImage,
        dataId,
        dataRank,
        dataRarityScore,
        raritySorting,
        rarityOptions,
        computedTraits,
        activeFilterStyle,
        dataCardClicked,
        traits,
        totalCount,
        filterAttribute,
        openSeaPrice,
        openSeaInfo,
        roundPrice,
        openMarketplace
    }
  },
  components: {
  },
}
</script>