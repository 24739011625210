import { createStore } from 'vuex';
import collectionStore from './collectionStore';
import projectStore from './projectStore';
import traitStore from './traitStore';
import routerStore from './routerStore';

const store = createStore({
    modules: {
        collectionStore: collectionStore,
        projectStore: projectStore,
        traitStore: traitStore,
        routerStore: routerStore,
    }
});

export default store;