const collectionStore = {
    namespaced: true,
    state: () => ({
        collection: [],
        currentPage: 1,
        totalPages: 1,
        totalCount: 0,
        collectionCount: 0,
        limit: 50,
        attributeFilter: [],
        rightSideBar: {
          isOpen: false,
          loading: false,
          nft: {},
        },
        collectionLoading: false,
        openSeaInfo: {},
    }),
    mutations: {
        setCollection(state, collection) {
          state.collection = collection;
        },
        setFocusedNFT(state, nft) {
          state.rightSideBar.nft = nft;
        },
        setToggleRightSideBar(state) {
          state.rightSideBar.isOpen = !state.rightSideBar.isOpen;
        },
        setLoadingRightSideBar(state, loading) {
          state.rightSideBar.loading = loading;
        },
        setTotalPages(state, pages) {
          state.totalPages = parseInt(pages);
        },
        setTotalCount(state, count) {
          state.totalCount = parseInt(count);
        },
        setCollectionCount(state, count) {
          state.collectionCount = parseInt(count);
        },
        setCurrentPage(state, page) {
          state.currentPage = parseInt(page);
        },
        resetRightSideBar(state) {
          state.rightSideBar = {
            isOpen: false,
            loading: false,
            nft: {},
          };
        },
        clearAttributeFilter(state) {
          state.attributeFilter = [];
        },
        addAttributeFilter(state, filter) {
          state.attributeFilter.push(filter)
        },
        removeAttributeFilter(state, index) {
          state.attributeFilter.splice(index, 1)
        },
        setCollectionLoading(state, loading) {
          state.collectionLoading = loading;
        },
        addOpenSeaInfo(state, info) {
          if ( state.openSeaInfo[info.shortName] ) {
            if ( state.openSeaInfo[info.shortName][info.tokenId] ) {
              state.openSeaInfo[info.shortName][info.tokenId].price = info.price;
              state.openSeaInfo[info.shortName][info.tokenId].owner = info.owner;
            }
            else {
              state.openSeaInfo[info.shortName][info.tokenId] = {
                price: info.price,
                owner: info.owner
              }
            }
          }
          else {
            state.openSeaInfo[info.shortName] = {};
            state.openSeaInfo[info.shortName][info.tokenId] = {
              price: info.price,
              owner: info.owner
            }
          }
        }
    },
    actions: {
        async getCollection(context, data) {
          let collectionURL = `${ ! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/collection/${data.uuid}?page=${data.page}&limit=${context.state.limit}&query=${data.query}`;

          context.commit('setCollectionLoading', true);

          context.state.attributeFilter.forEach(attribute => {
            collectionURL += `&${attribute.name}[]=${attribute.value}`
          })

          await fetch(collectionURL, {
            method: 'get',
            headers: { 'Content-type': 'application/json' },
          }).then( async (response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }

            let data = await response.json()

            await context.dispatch('fetchCollectionOpenSea',{ uuid: data.uuid, collection: data.collection });
            
            context.commit('setCollection', data.collection || []);
            context.commit('setTotalPages', Math.ceil(data.totalPages) || 1);
            context.commit('setTotalCount', data.totalCount || 0);
            context.commit('setCollectionCount', data.collectionCount || 0);
            context.commit('setCurrentPage', data.currentPage || 0);
            context.commit('setCollectionLoading', false);
          }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
          });
        },
        async getNFT(context, id) {
            await fetch(`${! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/collection/unique/${id}`, {
              method: 'get',
              headers: { 'Content-type': 'application/json' },
            }).then( async (response) => {
              if (!response.ok) {
                throw Error(response.statusText);
              }

              let data = await response.json()
              
              if ( data ) {
                context.commit('setFocusedNFT', data || []);
                if ( !context.getters.getOpenRightSideBar ) {
                  context.commit('setToggleRightSideBar');
                }
                context.commit('setLoadingRightSideBar', false);
              }
            }).catch((error) => {
              console.log('Looks like there was a problem: \n', error);
            });
        },
        async fetchCollectionOpenSea(context, data) {
          let ids = data.collection.map((obj) => obj.id),
           middleIndex = Math.ceil(ids.length / 2),
           firstHalf = ids.splice(0, middleIndex),
           secondHalf = ids.splice(-middleIndex);
          
          await context.dispatch('getOpenSeaInfo', firstHalf);
          await context.dispatch('getOpenSeaInfo', secondHalf);
        },
        async getOpenSeaInfo(context, ids) {
          let idString = '';

          ids.forEach((id) => {
            let string = `token_ids=${id}&`;

            idString = idString + string;
          })

          let openSeaURL = `https://api.opensea.io/api/v1/assets?${idString}asset_contract_address=${context.rootGetters['projectStore/getActiveProject'].contract_address}&order_direction=desc&offset=0&limit=50`

          await fetch(openSeaURL, {
            method: 'get',
            headers: { 'Content-type': 'application/json' },
          }).then( async (response) => {
            if (!response.ok) {
              throw Error(response.statusText);
            }

            let data = await response.json()
            
            if ( data && data.assets ) {
              let shortName = context.rootGetters['projectStore/getActiveProject'].short_name;

              data.assets.forEach((collection) => {
                let info = {
                    shortName: shortName,
                    tokenId: collection.token_id,
                    price: {},
                    owner: {}
                  },
                  sellOrder = collection.sell_orders && collection.sell_orders.length > 0 ? collection.sell_orders[0] : null
    
                if ( sellOrder ) {
                  info.price.basePrice = sellOrder.base_price / Math.pow(10, sellOrder.payment_token_contract.decimals );
                  info.price.symbol = sellOrder.payment_token_contract.symbol;
                  info.price.symbolURL = sellOrder.payment_token_contract.image_url;
                  info.price.usdPrice = sellOrder.payment_token_contract.usd_price;
                }
                info.owner.address = collection.owner.address;
  
                context.commit('addOpenSeaInfo', info);
              })
            }
          }).catch((error) => {
            console.log('Looks like there was a problem: \n', error);
          });
        },
        async filterAttribute(context, { name, value }) {
          let removalIndex = -1;

          context.commit('setCollectionLoading', true);

          context.getters['getAttributeFilter'].forEach((attribute, index) => {
            if (( attribute.name === name ) && ( String(attribute.value) === String(value) )) {
              removalIndex = index
            }
          });
  
          if ( removalIndex === -1 ) {
            // await context.dispatch('routerStore/addFilterRouterQuerys', {name, value:String(value)}, {root: true});

            await context.commit("addAttributeFilter", {
                name,
                value: String(value)
            })

            await context.dispatch('getCollection', {uuid: context.rootGetters['projectStore/getActiveProject'].uuid, page: 1, query: ''});
            context.commit('setCollectionLoading', false);
          }
          else {
            // await context.dispatch('routerStore/addFilterRouterQuerys', {name, value:String(value)}, {root: true});

            await context.commit("removeAttributeFilter", removalIndex)

            await context.dispatch('getCollection', {uuid: context.rootGetters['projectStore/getActiveProject'].uuid, page: 1, query: ''});
            context.commit('setCollectionLoading', false);
          }
      }
    },
    getters: {
       getCollection(state) {
        return state.collection;
       },
       getTotalPages(state) {
        return state.totalPages;
       },
       getTotalCount(state) {
        return state.totalCount;
       },
       getCollectionCount(state) {
        return state.collectionCount;
       },
       getCurrentPage(state) {
        return state.currentPage;
       },
       getLimit(state) {
        return state.limit;
       },
       getFocusedNFT(state) {
        return state.rightSideBar.nft;
       },
       getOpenRightSideBar(state) {
        return state.rightSideBar.isOpen;
       },
       getLoadingRightSideBar(state) {
        return state.rightSideBar.loading;
       },
       getAttributeFilter(state) {
        return state.attributeFilter;
       },
       getCollectionLoading(state) {
        return state.collectionLoading;
       },
       getNFTOpenSeaPrice(state) {
         return (shortName, tokenId) => {
           if ( state.openSeaInfo[shortName] && state.openSeaInfo[shortName][tokenId] && state.openSeaInfo[shortName][tokenId].price ) {
             return state.openSeaInfo[shortName][tokenId].price;
           }
           return;
         }
       },
       getNFTOpenSeaInfo(state) {
         return (shortName, tokenId) => {
          if ( state.openSeaInfo[shortName] && state.openSeaInfo[shortName][tokenId] && state.openSeaInfo[shortName][tokenId].owner ) {
           return state.openSeaInfo[shortName][tokenId].owner;
          }
          return;
         }
       }
    }  
}

export default collectionStore;