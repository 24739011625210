const traitStore = {
    namespaced: true,
    state: () => ({
        traits: {},
        traitsLoading: false,
    }),
    mutations: {
        setTraits(state, data) {
          state.traits[data.projectId] = data.traits;
        },
        setTraitsLoading(state, loading) {
          state.traitsLoading = loading;
        },
    },
    actions: {
        async getTraitsByProject(context, projectId) {
            if (!context.state.traits[projectId]) {
                await fetch(`${! process.env.VUE_APP_LOCAL ? '' : 'http://localhost:3000'}/trait/${projectId}`, {
                  method: 'get',
                  headers: { 'Content-type': 'application/json' },
                }).then( async (response) => {
                  if (!response.ok) {
                    throw Error(response.statusText);
                  }
    
                  let data = await response.json()
                  context.commit('setTraits', {traits:data.trait.traits, projectId: projectId});
                }).catch((error) => {
                  console.log('Looks like there was a problem: \n', error);
                });
            }
        },
    },
    getters: {
       getTraits(state) {
        return state.traits;
       },
       getLocalTraitsByProject(state, projectId) {
        return state.traits[projectId] || {};
       },
       getActiveProject(state) {
        return state.activeProject;
       },
       getTraitsLoading(state) {
         return state.traitsLoading;
       }
    }  
}

export default traitStore;