<template>
    <q-page padding class="q-pa-none bg-secondary text-accent" style="height: calc( 100vh - 35px );">
        <q-scroll-area v-if="activeProject" class="bg-secondary" style="height: calc( 100% - 30px );">
            <div class="q-pa-none flex bg-secondary">
                <q-linear-progress v-if="projectLoading" indeterminate color="text" />
                <q-img img-class="bg-black" :src="activeProject.banner_image_url" style="height:300px;"/>
                <q-img ratio="1" style="max-height: 150px; max-width: 150px; border-radius: 50%; margin-top: -75px; margin-left: 30px; border: 2px solid #F6E9A2;" round img-class="bg-black" :src="activeProject.profile_image_url"/>
                <div class="q-px-md q-py-sm flex bg-secondary" style="width: calc( 100% - 180px );">
                    <div class="flex row justify-between" style="width: 100%;">
                        <div class="text-h5 text-accent text-weight-bold" v-if="activeProject">{{activeProject.name}}</div>
                        <div class="q-pr-md q-pa-none">
                            <q-btn-group outline>
                                <q-btn v-if="activeProject.website_url" color="accent" outline @click="openLink(activeProject.website_url)">
                                    <q-icon name="web" color="accent"/>
                                </q-btn>
                                <q-btn v-if="activeProject.discord_url" color="accent" outline @click="openLink(activeProject.discord_url)">
                                    <q-icon name="fab fa-discord" color="accent"/>
                                </q-btn>
                                <q-btn v-if="activeProject.twitter_url" color="accent" outline @click="openLink(activeProject.twitter_url)">
                                    <q-icon name="fab fa-twitter" color="accent"/>
                                </q-btn>
                            </q-btn-group>
                        </div>
                    </div>
                </div>
            </div>
            <div class="q-px-xl q-py-md flex flex-center bg-secondary">
                <div class="text-body2 text-accent q-pt-md" style="width: 100%;" v-if="activeProject">{{activeProject.description}}</div>
            </div>
            <div class="q-px-xl q-py-none flex row justify-between flex-center">
                <q-skeleton v-if="projectLoading" height="45px" width="300px" type="text" animation="fade" />
                <div v-else class="text-body1 text-text">{{addDecimals(collectionCount)}} {{attributeFilter.length > 0 ? ' filtered ' : ' total '}} {{activeProject.name}}</div>
                <div v-if="false">
                    <q-btn-toggle
                        v-model="raritySorting"
                        class="rarity-toggle"
                        no-caps
                        rounded
                        unelevated
                        toggle-color="primary"
                        color="border"
                        text-color="accent"
                        :options="rarityOptions"
                    />
                </div>
            </div>
            <div class="q-px-xl q-py-none flex row">
                <div v-for="(filter, index) in attributeFilter" :key="index">
                    <q-chip class="q-pr-xs q-pl-sm" style="text-transform:capitalize; padding-right: 5px;" removable @remove="removeAttributeFilter(index)" color="primary" text-color="accent" dense>{{filter.name}}: {{filter.value === 'total_count' ? "None" : filter.value}}</q-chip>
                </div>
            </div>
            <div v-if="projectLoading || paginateLoading || collectionLoading" class="q-px-md q-pb-md q-pt-none row q-gutter-sm justify-center">
                <SkeletonCard v-for="(index) in [...Array(50).keys()]" :key="index"></SkeletonCard>
            </div>
            <div v-else class="q-px-md q-pb-md q-pt-none row q-gutter-sm justify-center">
                <DataCard 
                    v-for="(nft) in collection"
                    :key="nft.id"
                    :data="nft"
                    :onlyCard="true"
                    dataType="nft"
                ></DataCard>
            </div>
        </q-scroll-area>
        <div class="q-px-sm  q-py-none flex justify-end bg-secondary" style="min-height: 30px;max-height: 30px;">
            <q-pagination
                v-if="collection.length"
                :model-value="currentPage"
                color="text"
                active-color="yellow"
                active-text-color="black"
                :max="totalPages"
                :max-pages="8"
                input
                input-class="text-accent bordered"
                @update:model-value="paginateUpdate"
            />
        </div>
    </q-page>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import DataCard from '../components/DataCard.vue';
import SkeletonCard from '../components/SkeletonCard.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'RarityView',
  props: {
  },
  setup () {
    const store = useStore(),
        router = useRouter(),
        raritySorting = ref("rarity"),
        rarityOptions = ref([
            {label: 'Normalized Score', value: 'normalized'},
            {label: 'Rarity Score', value: 'rarity'}
        ]),
        paginateLoading = ref(false),
        collectionLoading = computed(() => store.getters["collectionStore/getCollectionLoading"]),
        collection = computed(() => store.getters["collectionStore/getCollection"]),
        currentPage = computed(() => store.getters["collectionStore/getCurrentPage"]),
        totalPages = computed(() => store.getters["collectionStore/getTotalPages"]),
        totalCount = computed(() => store.getters["collectionStore/getTotalCount"]),
        collectionCount = computed(() => store.getters["collectionStore/getCollectionCount"]),
        limit = computed(() => store.getters["collectionStore/getLimit"]),
        projectLoading = computed(() => store.getters["projectStore/getProjectLoading"]),
        activeProject = computed(() => store.getters["projectStore/getActiveProject"]),
        attributeFilter = computed(() => store.getters["collectionStore/getAttributeFilter"]);

    const fetchData = async (page=1) => {
      await store.dispatch('collectionStore/getCollection', {uuid: store.getters["projectStore/getActiveProject"].uuid ,page, query: '' })
      
    };

    const addDecimals = (number) => {
        return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    };

    const removeAttributeFilter = async (index) => {
      await store.commit("collectionStore/removeAttributeFilter", index)

      await store.dispatch('collectionStore/getCollection', {uuid: activeProject.value.uuid, page: 1, query: ''});
    }

    const paginateUpdate = async (value) => {
        const query = {};
        query.page = value || 1;
        query.limit = router.currentRoute.value.query.limit || 50;
        await router.push({ query });
        
        paginateLoading.value = true;
        await fetchData(value)
        paginateLoading.value = false;
    };

    const openLink = async (value) => {
        window.open(value, '_blank').focus();
    }

    onMounted( async () => {
        let currentPage = router.currentRoute.value.query.page,
            pageLimit = router.currentRoute.value.query.limit;

        if (router.currentRoute.value.params.shortName) {
            await store.dispatch('projectStore/getProjectByShortName', {shortName: router.currentRoute.value.params.shortName, page: 1, query: '' })
        }
        
        if (router.currentRoute.value.params.focusedNFT) {
          await store.dispatch('collectionStore/getNFT',`${router.currentRoute.value.params.focusedNFT}`);
        }

        if ( !currentPage || !pageLimit) {
            const query = {};
            query.page = currentPage || 1;
            query.limit = pageLimit || 50;
            await router.push({ query });
            await store.commit('collectionStore/setCurrentPage', currentPage)
            await fetchData(currentPage);
        }
        else {
            await store.commit('collectionStore/setCurrentPage', currentPage)
            await fetchData(currentPage);
        }
    });

    return {
        collection,
        currentPage,
        totalPages,
        totalCount,
        collectionCount,
        limit,
        projectLoading,
        activeProject,
        paginateUpdate,
        openLink,
        raritySorting,
        rarityOptions,
        attributeFilter,
        removeAttributeFilter,
        paginateLoading,
        collectionLoading,
        addDecimals
    }
  },
  components: {
    DataCard,
    SkeletonCard,
  },
}
</script>
