import { useRouter } from '../router/index';

const routerStore = {
    namespaced: true,
    state: () => ({
    }),
    mutations: {
    },
    actions: {
        async routerPush({},route) {
            const router = useRouter(),
                currentPage = router.currentRoute.value.query.page,
                pageLimit = router.currentRoute.value.query.limit,
                query = {};
            
            query.page = route.query && route.query.page ? route.query.page : currentPage || 1;
            query.limit = pageLimit || 50;
            route.query = query
            
            await router.push(route)
        },
        async addFilterRouterQuerys({}, filter) {
            const router = useRouter(),
                query = router.currentRoute.value.query;
            
            query[filter.name] = filter.value
            
            await router.replace({name: router.currentRoute.value.name, query: query})
        },
    },
    getters: {
    }  
}

export default routerStore;