<template>
  <q-layout view="hHh Lpr lff">
    <q-header dense class="bg-primary text-accent row" style="min-height: 30px; max-height: 30px; overflow: hidden;">
      <q-avatar @click="goToHome" class="cursor-pointer" style="padding-left: 8px; margin-top: -2px; border-radius: 0px; max-width: 27px;"><q-img src="./assets/open-rarity-path.svg" :img-style="{ 'max-height': '18px', 'max-width': '27px' }"/></q-avatar>
      <q-space/>
      <!-- <q-btn flat round dense>
        <q-icon name="fab fa-twitter" class="self-center"/>
      </q-btn> -->
      <!-- <q-separator inset spaced dark/> -->
      <!-- <q-btn flat round dense>
        <q-icon name="fab fa-discord" class="self-center"/>
      </q-btn> -->
    </q-header>
    <q-drawer
        v-model="drawerLeft"
        show-if-above
        :mini="!drawerLeft || drawerLeftMini"
        :width="300"
        :breakpoint="500"
        elevated
        class="bg-border text-text"
      >
      <q-scroll-area class="fit q-pt-xl">
        <div class="q-pa-sm" style="max-width: 100%;">
          <div v-if="!drawerLeftMini" class="flex justify-begin" style="max-width: 100%;">
            <div class="text-h6 text-accent q-pb-sm flex row justify-between" style="width: 100%;">
              <div>Projects</div>
              <q-icon class="q-pr-xs" name="collections" color="text" style="font-size: 1.2em;"/>
            </div>
            <q-select outlined :model-value="activeProject.name" :options="projects" dense options-dense bg-color="lightPrimary" option-value="id" option-label="name" style="max-width: 100%; width:100%;" @update:model-value="projectUpdate"></q-select>
          </div>
          <div v-else class="q-pl-xs q-pt-md">
            <q-btn dense flat color="text" icon="collections" @click="miniDrawerButtonClick"/>
          </div>
        </div>
        <div v-if="activeProject.uuid" class="q-py-lg q-pa-sm row" style="width: 100%;">
          <div v-if="!drawerLeftMini" class="flex flex-center justify-between" style="width: 100%;">
            <q-input
                v-model="rarityQuery"
                dense
                outlined
                label="Search for ID"
                bg-color="lightPrimary"
                color="text"
                class="text-text"
                style="width: calc( 100% - 40px );"
            >
                <template v-slot:append>
                    <q-icon v-if="rarityQuery.length" name="close" color="text" @click.stop="clearSearch" class="cursor-pointer" style="margin-top: 0px;" />
                </template>
            </q-input>
            <q-btn dense flat @click="queryUpdate">
              <q-icon color="text" name="search"/>
            </q-btn>
          </div>
          <div v-else class="q-pl-xs">
            <q-btn dense flat color="text" icon="search" @click="miniDrawerButtonClick"/>
          </div>
        </div>
        <div v-if="activeProject.uuid" class="q-pa-sm" style="max-width: 100%;">
          <div v-if="!drawerLeftMini" class="flex justify-begin column" style="max-width: 100%; text-transform: capitalize">
            <div class="text-h6 text-accent flex row justify-between" style="width: 100%;">
              <div>Filter by Trait</div>
              <q-icon class="q-pr-xs" name="filter_list" color="text" style="font-size: 1.2em;"/>
            </div>
            <q-list class="rounded-borders">
              <div v-for="(trait, index) in Object.keys(traits)" :key="index">
                <q-expansion-item
                  switch-toggle-side
                  expand-separator
                  expand-icon="arrow_drop_down"
                  :label="trait"
                  class="text-accent"
                >
                  <div v-if="(totalCount - traits[trait]['total_count']) > 0" class="q-px-xs q-py-none column" @click="filterAttribute(trait, 'total_count')">
                    <div class="q-pt-xs q-px-xs row justify-between align-center" style="width: 100%;"> 
                        <div :class="`text-body2 text-accent row ${activeFilterStyle(trait,'total_count')}`" style="border-radius: 12px; text-transform:capitalize; width: 100%;">
                          <div class="col-8" style="padding: 2px;"><div class="q-pl-sm">{{"None"}}</div></div>
                          <div class="col-4" style="padding: 2px;"><div class="q-pr-sm flex justify-end bg-primary text-text" style="border-radius: 12px; text-transform:capitalize;">{{totalCount - traits[trait]["total_count"]}}</div></div>
                        </div>
                    </div>
                  </div>
                  <div class="q-px-xs q-py-none column" v-for="(attribute, index1) in Object.keys(traits[trait]).sort()" :key="index1" @click="filterAttribute(trait, attribute)">
                    <div v-if="attribute !== 'total_count'" class="q-pt-xs q-px-xs row justify-between align-center" style="width: 100%;"> 
                        <div :class="`text-body2 text-accent row ${activeFilterStyle(trait,attribute)}`" style="border-radius: 12px; text-transform:capitalize; width: 100%;">
                          <div class="col-8" style="padding: 2px;"><div class="q-pl-sm">{{attribute}}</div></div>
                          <div class="col-4" style="padding: 2px;"><div class="q-pr-sm flex justify-end bg-primary text-text" style="border-radius: 12px; text-transform:capitalize;">{{traits[trait][attribute]}}</div></div>
                        </div>
                    </div>
                  </div>
                </q-expansion-item>
              </div>
            </q-list>
          </div>
          <div v-else class="q-pl-xs">
            <q-btn dense flat color="text" icon="filter_list" @click="miniDrawerButtonClick"/>
          </div>
        </div>
      </q-scroll-area>
      <div class="absolute" :style="drawerLeftMini ? 'top: 12px; right: 13px' : 'top: 12px; right: 8px'">
        <q-btn
          dense
          flat
          unelevated
          color="text"
          icon="menu_open"
          :style="drawerLeftMini ? 'transform: rotate(180deg);' : ''"
          @click="drawerLeftMini = !drawerLeftMini"
        />
      </div>
      <!-- <div v-if="drawerLeftMini" class="absolute" style="bottom: 8px; right: 13px">
        <q-btn
          dense
          flat
          unelevated
          color="accent"
          icon="fab fa-twitter"
        />
      </div> -->
    </q-drawer>
    <q-drawer
        side="right"
        v-model="drawerRight"
        :width="300"
        :breakpoint="700"
        elevated
        class="bg-border text-text"
      >
      <div class="q-px-sm q-pt-sm q-pb-none flex justify-start" style="width: 100%;">
        <q-btn
          dense
          flat
          unelevated
          color="text"
          icon="fas fa-times"
          @click="closeRightSideBar"
        />
      </div>
      <q-scroll-area class="fit" style="height: calc( 100vh - 70px ) !important;">
        <div class="q-pa-sm">
          <DataCard v-if="focusedNFT.id && ! focusedNFTLoading" :data="focusedNFT"/>
        </div>
      </q-scroll-area>
    </q-drawer>
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import DataCard from './components/DataCard.vue'


export default {
  name: 'App',
  setup () {
    const store = useStore(),
      rarityQuery = ref(''),
      drawerLeft= ref(false),
      drawerLeftMini= ref(true),
      activeProject = computed(() => store.getters["projectStore/getActiveProject"]),
      drawerRight = computed(() => store.getters["collectionStore/getOpenRightSideBar"]),
      focusedNFT = computed(() => store.getters["collectionStore/getFocusedNFT"]),
      projects = computed(() => store.getters["projectStore/getProjects"]),
      focusedNFTLoading = computed(() => store.getters["collectionStore/getLoadingRightSideBar"]),
      projectLoading = computed(() => store.getters["projectStore/getProjectLoading"]),
      traitLoading = computed(() => store.getters["traitStore/getTraitsLoading"]),
      totalCount = computed(() => store.getters["collectionStore/getTotalCount"]),
      traits = computed(() => store.getters["traitStore/getTraits"][activeProject.value.uuid] || []),
      activeAttributeFilter = computed(() => {
        const attributeFilter = store.getters["collectionStore/getAttributeFilter"],
          active = {};

        attributeFilter.forEach(attribute => {
          if ( active[attribute.name] ) {
            active[attribute.name].push(attribute.value)
          }
          else {
            active[attribute.name] = [attribute.value]
          }
        })

        return active;
      });

    const filterAttribute = async (name, value) => {
      await store.dispatch('collectionStore/filterAttribute', {name,value})
    }

    const toggleRightSideBar = () => {
      store.commit("collectionStore/setToggleRightSideBar");
    };

    const miniDrawerButtonClick = () => {
      drawerLeftMini.value = false;
      // store.commit("collectionStore/resetRightSideBar");
    };

    const closeRightSideBar = () => {
      store.commit("collectionStore/resetRightSideBar");
      rarityQuery.value = '';
      store.dispatch("routerStore/routerPush", {name:'rarityView', params: {shortName: activeProject.value.short_name, focusedNFT: undefined}});
    };
    
    const queryUpdate = async () => {
      store.commit('collectionStore/setLoadingRightSideBar', true)
      store.dispatch('collectionStore/getNFT',`${rarityQuery.value}-${activeProject.value.uuid}`);
    };
    
    const projectUpdate = async (project) => {
      store.commit('projectStore/setProjectLoading', true);
      store.commit("collectionStore/resetRightSideBar");

      await store.dispatch('projectStore/getProjectById', project.uuid);
      await store.dispatch('traitStore/getTraitsByProject', project.uuid );
      await store.dispatch('routerStore/routerPush', {name: 'rarityView', params:{ shortName:`${activeProject.value.short_name}`}, query: {page:1}});
    };
    
    const clearSearch = () => {
        rarityQuery.value = '';
        queryUpdate();
    };

    const goToHome = () => {
      store.commit("projectStore/setActiveProject", {});
      store.commit("collectionStore/resetRightSideBar");
      store.dispatch("routerStore/routerPush", {name:'home'});
    };

    const activeFilterStyle = (name, value) => {
      const attributeList = activeAttributeFilter.value[name] || []

      return attributeList.indexOf(String(value)) > -1 ? 'active-attribute cursor-pointer' : 'bg-secondary cursor-pointer';
    }

    onMounted( async () => {
      console.log(process.env.VUE_APP_LOCAL)
      await store.dispatch('projectStore/getProjects');
    });

    return {
      drawerLeft,
      drawerLeftMini,
      drawerRight,
      rarityQuery,
      focusedNFT,
      focusedNFTLoading,
      projects,
      activeProject,
      projectLoading,
      traitLoading,
      traits,
      activeFilterStyle,
      toggleRightSideBar,
      miniDrawerButtonClick,
      queryUpdate,
      clearSearch,
      projectUpdate,
      goToHome,
      closeRightSideBar,
      totalCount,
      filterAttribute
    }
  },
  components: {
    DataCard
  },
}
</script>

<style>
.q-field--outlined .q-field__control:before {
  border: 2px solid #F6E9A2 !important;
}
.q-field__label {
    color: #FCF7FF !important;
    margin-top: -5px;
    font-size: 12px !important;
}
.q-field__native {
    color: #FCF7FF !important;
    min-height: 30px !important;
}
.q-field__append {
    color: #F6E9A2 !important;
}
.q-field--dense {
    height: 30px;
}
.q-field__control {
    height: 30px !important;
    min-height: 30px !important;
}
.q-field--labeled {
    padding-top: 0px !important;
}
.q-field__append {
  height: 30px !important;
}
/* .q-item {
    padding: 4px 8px !important;
    min-height: 32px !important;
    border-radius: 4px;
} */
.active-attribute {
  box-shadow: 0 0 0 1px #F6E9A2 !important;
  background-color: #768273 !important;
}
</style>
