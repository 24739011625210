import { createRouter, createWebHistory } from 'vue-router'
import RarityView from "../views/RarityView";
import HomeView from "../views/HomeView";
import ChallengeView from "../views/ChallengeView";

const routes = [
    { 
        name: 'home',
        path: '/',
        component: HomeView
    },
    { 
        name: 'rarityView',
        path: '/:shortName/rarity',
        component: RarityView
    },
    { 
        name: 'focusedRarity',
        path: '/:shortName/rarity/:focusedNFT',
        component: RarityView
    },
    { 
        name: 'challengeView',
        path: '/:shortName/challenge',
        component: ChallengeView
    },
]
  
const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;

export function useRouter() {
    return router
  }